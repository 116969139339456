<template>
  <div class="max-w-screen-lg mx-auto bg-white mb-[60px]">
      <div class="grid grid-cols-1 md:grid-cols-12 border" @submit.prevent="submitForm" v-if="success == false">
          <form class="md:col-span-12 py-5 pr-5 pl-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full">
                      <label class="block tracking-wide text-black text-xs font-[700] mb-2 ml-3" for="grid-first-name">
                          Name <span class="text-red-500 font-normal">*</span>
                      </label>
                  </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" v-model="firstName" @input="validateFirstname" />
                      <p v-if="firstNameError" class="text-red-500 text-xs italic">{{ firstNameError }}</p>
                      <label class="block tracking-wide text-gray-400 text-[13px] font-[600] mb-2" for="grid-first-name">
                          First Name
                      </label>
                  </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" v-model="lastName" @input="validateLastname" />
                      <p v-if="lastNameError" class="text-red-500 text-xs italic">{{ lastNameError }}</p>
                      <label class="block tracking-wide text-gray-400 text-[13px] font-[600] mb-2" for="grid-last-name">
                          Last Name
                      </label>
                  </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block tracking-wide text-black text-xs font-[700] mb-2" for="grid-company">
                          Company <span class="text-red-500 font-normal">*</span>
                      </label>
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" v-model="company" @input="validateCompany" />
                      <p v-if="companyError" class="text-red-500 text-xs italic">{{ companyError }}</p>
                      
                  </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block tracking-wide textblack text-xs font-[700] mb-2" for="grid-company">
                          Phone Number <span class="text-red-500 font-normal">*</span>
                      </label>
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="phone" v-model="phone" @input="validatePhone" v-maska="'(###) ###-####'" />
                      <p v-if="phoneError" class="text-red-500 text-xs italic">{{ phoneError }}</p>
                      <span class="block tracking-wide text-gray-400 text-[13px] font-[600] mb-2" for="grid-last-email">
                          (123) 456-7890
                      </span>
                  </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block tracking-wide text-black text-xs font-[700] mb-2" for="grid-province">
                          Province <span class="text-red-500 font-normal">*</span>
                      </label>
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-province" type="text" v-model="province" @input="validateProvince" />
                      <p v-if="provinceError" class="text-red-500 text-xs italic">{{ provinceError }}</p>
                      
                  </div>
                  <div class="w-full md:grid md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-black text-xs font-[700] mb-2" for="grid-email">
                          Email <span class="text-red-500 font-normal">*</span>
                      </label>
                      <input class="appearance-none block w-full bg-gray-200 text-gray-700 seravek border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="email" v-model="email" @input="validateEmail" />
                      <p v-if="emailError" class="text-red-500 text-xs italic">{{ emailError }}</p>
                      <span class="block tracking-wide text-gray-400 text-[13px] font-[600] mb-2" for="grid-last-email">
                          example@example.com
                      </span>
                  </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6" >
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-black text-xs font-[700] mb-2" for="grid-website">
                      Company Website <span class="text-red-500 font-normal">*</span>
                    </label>
                    <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-company-website" type="text" v-model="website" @input="validateWebsite" />
                    <p v-if="websiteError" class="text-red-500 text-xs italic">{{ websiteError }}</p>
                    <span class="block tracking-wide text-gray-400 text-[13px] font-[600] mb-2" for="grid-last-email">
                        example.com
                    </span>
                  </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <vue-recaptcha
                          sitekey="6LeJZ_4pAAAAAOI9XFnLqr6dbGQ4flTjpqk-nYrq"
                          @verify="onVerify"
                          @expired="onExpired"
                      />
                  </div>
              </div>
              <div class="flex flex-wrap -mx-3">
                  <div class="flex justify-between w-full px-3">
                      
                      <button class="yellowbtn text-center mx-auto" type="submit" :disabled="!isFormValid">
                          Become A Dealer
                      </button>
                  </div>
              </div>
          </form>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-12 border" v-if="success == true"  >
          <div class="md:col-span-12 py-5 pr-5 pl-5">
            <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
              <div class="flex">
                <div>
                  <p class="font-bold">Thank you!</p>
                  <p class="text-sm">We will be in touch with you soon!</p>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { vMaska } from "maska/dist/vue.js"
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'EmailForm',
  components: {
      VueRecaptcha
  },
  directives: {
    maska: vMaska
  },
  setup() {
    const firstName = ref('');
    const firstNameError = ref('');
    const lastName = ref('');
    const lastNameError = ref('');
    const company = ref('');
    const companyError = ref('');
    const phone = ref('');
    const phoneError = ref('');
    const province = ref('');
    const provinceError = ref('');
    const email = ref('');
    const emailError = ref('');
    const website = ref('');
    const websiteError = ref('');
    const isRecaptchaVerified = ref(false);
    const recaptchaResponse = ref('');
    const isSubmitting = ref(false);
    let success = ref(false);

    const validateFirstname = () => {
      if (firstName.value === '') {
          firstNameError.value = 'This field is required';
      } else {
          firstNameError.value = '';
      }
    };

    const validateLastname = () => {
      if (lastName.value === '') {
          lastNameError.value = 'This field is required';
      } else {
          lastNameError.value = '';
      }
    };

    const validateCompany = () => {
      if (company.value === '') {
          companyError.value = 'This field is required';
      } else {
          companyError.value = '';
      }
    };

    const validatePhone = () => {
      if (phone.value === '') {
          phoneError.value = 'This field is required';
      } else {
          phoneError.value = '';
      }
    };

    const validateProvince = () => {
      if (province.value === '') {
          provinceError.value = 'This field is required';
      } else {
          provinceError.value = '';
      }
    };

    const validateWebsite = () => {
      if (website.value === '') {
          websiteError.value = 'This field is required';
      } else {
          websiteError.value = '';
      }
    };

    const validateEmail = () => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!email.value.match(emailPattern)) {
        emailError.value = 'Please enter a valid email address';
      } else {
        emailError.value = '';
      }
    };

    const isFormValid = computed(() => {
      return  firstNameError.value === '' && 
              lastNameError.value === '' && 
              companyError.value === '' && 
              phoneError.value === '' && 
              provinceError.value === '' && 
              emailError.value === '' && 
              websiteError.value === ''  && 
              firstName.value !== '' && 
              lastName.value !== '' && 
              phone.value !== '' && 
              company.value !== '' && 
              province.value !== '' && 
              email.value !== '' && 
              website.value !== '';
    });

    const onVerify = (response) => {
      isRecaptchaVerified.value = true;
      recaptchaResponse.value = response;
    };

    const onExpired = () => {
      isRecaptchaVerified.value = false;
      recaptchaResponse.value = '';
    };

    const submitForm = async () => {
      console.log('submitting form');
      if (isFormValid.value && isRecaptchaVerified.value) {
        isSubmitting.value = true;
        try {
          // console.log(firstName.value);
          // console.log(lastName.value);
          // console.log(company.value);
          // console.log(phone.value);
          // console.log(province.value);
          // console.log(email.value);
          // console.log(website.value);
          // console.log(recaptchaResponse.value);

          const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              access_key: '47d3d8ba-1d96-4398-a261-b72b7436adc7',
              name: firstName.value + ' ' + lastName.value,
              company: company.value,
              phone: phone.value,
              province: province.value,
              email: email.value,
              website: website.value,
            }),
          });
          let result = await response.json();
          if (result.success) {
            success.value = true;
          }



        } catch (error) {
          console.error('Error:', error);
        } finally {
          isSubmitting.value = false;
        }
      }
    };


    return {
      firstName,
      firstNameError,
      validateFirstname,
      lastName,
      lastNameError,
      validateLastname,
      company,
      companyError,
      validateCompany,
      phone,
      phoneError,
      validatePhone,
      province,
      provinceError,
      validateProvince,
      email,
      emailError,
      validateEmail,
      website,
      websiteError,
      validateWebsite,
      isRecaptchaVerified,
      recaptchaResponse,
      isFormValid,
      submitForm,
      onVerify,
      onExpired,
      success
    };
  }
};
</script>

<style scoped>
.email-form {
  max-width: 400px;
  margin: 0 auto;
}

.error {
  color: red;
  font-size: 0.875em;
}
</style>
